// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import { AppDataFragmentDoc } from '../fragments/AppData.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectsQueryVariables = Types.Exact<{
  offset: Types.Scalars['Int']['input'];
  limit: Types.Scalars['Int']['input'];
  accountName: Types.Scalars['String']['input'];
  includeUnpublished: Types.Scalars['Boolean']['input'];
}>;


export type ProjectsQuery = { __typename?: 'RootQuery', account: { __typename?: 'AccountQuery', byName: { __typename?: 'Account', id: string, apps: Array<{ __typename: 'App', id: string, iconUrl?: string | null, fullName: string, name: string, slug: string, lastDeletionAttemptTime?: any | null, icon?: { __typename?: 'AppIcon', url: string, primaryColor?: string | null } | null, ownerAccount: { __typename?: 'Account', name: string, id: string }, githubRepository?: { __typename?: 'GitHubRepository', githubRepositoryUrl?: string | null } | null }> } } };


export const ProjectsQueryDocument = gql`
    query ProjectsQuery($offset: Int!, $limit: Int!, $accountName: String!, $includeUnpublished: Boolean!) {
  account {
    byName(accountName: $accountName) {
      id
      apps(limit: $limit, offset: $offset, includeUnpublished: $includeUnpublished) {
        ...AppData
      }
    }
  }
}
    ${AppDataFragmentDoc}`;
export function useProjectsQuery(baseOptions: Apollo.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables> & ({ variables: ProjectsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsQueryDocument, options);
      }
export function useProjectsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsQueryDocument, options);
        }
export function useProjectsQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsQueryDocument, options);
        }
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsQueryLazyQueryHookResult = ReturnType<typeof useProjectsQueryLazyQuery>;
export type ProjectsQuerySuspenseQueryHookResult = ReturnType<typeof useProjectsQuerySuspenseQuery>;
export type ProjectsQueryQueryResult = Apollo.QueryResult<ProjectsQuery, ProjectsQueryVariables>;
export function refetchProjectsQuery(variables: ProjectsQueryVariables) {
      return { query: ProjectsQueryDocument, variables: variables }
    }