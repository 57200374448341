import { ArrowRightIcon } from '@expo/styleguide-icons/outline/ArrowRightIcon';
import { useState } from 'react';

import { useProjectsQuery } from '~/graphql/queries/ProjectsQuery.query.generated';
import { Button } from '~/ui/components/Button';
import { DialogContent } from '~/ui/components/Dialog/DialogContent';
import { DialogContentContainer } from '~/ui/components/Dialog/DialogContentContainer';
import { DialogFooter } from '~/ui/components/Dialog/DialogFooter';
import { DialogRoot } from '~/ui/components/Dialog/DialogRoot';
import { DialogSeparator } from '~/ui/components/Dialog/DialogSeparator';
import { DialogSuccessContent } from '~/ui/components/Dialog/DialogSuccessContent';
import { DialogTitle } from '~/ui/components/Dialog/DialogTitle';
import { Terminal } from '~/ui/components/Snippet/blocks/Terminal';
import { CALLOUT, H4 } from '~/ui/components/text';

import { ProjectCreationForm } from './ProjectCreationForm';

type Props = {
  onRequestClose: () => void;
  accountName: string;
  accountId: string;
  refetchProjects?: () => Promise<any>;
  onComplete: (slug: string) => void;
  isCreateFormVisible?: boolean;
};

export function ProjectCreationDialog({
  onRequestClose,
  accountName,
  accountId,
  refetchProjects,
  isCreateFormVisible,
  onComplete,
}: Props) {
  const [showSecondStep, setShowSecondStep] = useState(false);
  const [acceptedSlug, setAcceptedSlug] = useState<string | undefined>(undefined);
  const [newId, setNewId] = useState<string | undefined>(undefined);

  const { refetch } = useProjectsQuery({
    variables: {
      offset: 0,
      limit: 50,
      accountName,
      includeUnpublished: true,
    },
    skip: !newId,
  });

  function resetState() {
    setAcceptedSlug(undefined);
    setNewId(undefined);
    setShowSecondStep(false);
  }

  const createCommand = [
    '$ npm install --global eas-cli',
    `$ npx create-expo-app ${acceptedSlug}`,
    `$ cd ${acceptedSlug}`,
    `$ eas init --id ${newId}`,
  ];

  const linkCommand = ['$ npm install --global eas-cli', `$ eas init --id ${newId}`];

  return (
    <>
      <DialogRoot open={isCreateFormVisible && !showSecondStep} onOpenChange={onRequestClose}>
        <DialogContent>
          <DialogTitle title="Create a project" />
          <ProjectCreationForm
            accountId={accountId}
            accountName={accountName}
            onComplete={async (slug, id) => {
              if (refetchProjects) {
                await refetchProjects();
              }
              await refetch();

              setAcceptedSlug(slug);
              setNewId(id);

              setShowSecondStep(true);
            }}
            onRequestClose={onRequestClose}
          />
        </DialogContent>
      </DialogRoot>
      <DialogRoot
        open={isCreateFormVisible && showSecondStep}
        onOpenChange={async () => {
          onRequestClose();
          if (refetchProjects) {
            await refetchProjects();
          }
          setTimeout(resetState, 50);
        }}>
        <DialogContent>
          <DialogContentContainer>
            <DialogSuccessContent title="Successfully created project" />
            <DialogSeparator className="mb-2" />
            <H4>Start developing your project</H4>

            <CALLOUT>
              You just created a new EAS project. Now, it's time to link this to your local project.
              There are two ways to accomplish this:
            </CALLOUT>

            <H4>Create a new project</H4>
            <Terminal
              cmd={createCommand}
              cmdCopy={createCommand.map((s) => s.replace('$ ', '')).join(' && ')}
              className="relative z-[1] mb-6 max-w-[500px]"
            />

            <H4>Link an existing codebase</H4>
            <CALLOUT>Run the following commands in your project root directory:</CALLOUT>
            <Terminal
              cmd={linkCommand}
              cmdCopy={linkCommand.map((s) => s.replace('$ ', '')).join(' && ')}
              className="relative z-[1] mb-6 max-w-[500px]"
            />
          </DialogContentContainer>
          <DialogFooter>
            <Button
              onClick={() => {
                onComplete(acceptedSlug ?? '');
              }}
              rightSlot={<ArrowRightIcon />}>
              View project
            </Button>
          </DialogFooter>
        </DialogContent>
      </DialogRoot>
    </>
  );
}
