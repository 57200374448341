import { ApolloError } from '@apollo/client';
import { ChevronRightIcon } from '@expo/styleguide-icons/outline/ChevronRightIcon';
import { Trash03Icon } from '@expo/styleguide-icons/outline/Trash03Icon';
import { ReactElement, ReactNode, useState } from 'react';

import { BackgroundJobReceiptDataFragment, BackgroundJobState } from '~/graphql/types.generated';
import { useGetBackgroundJobReceiptByIdLazyQuery } from '~/scenes/Dashboard/Projects/BuildsListScene/queries/GetBackgroundJobReceiptById.query.generated';
import { ActivityIndicator } from '~/ui/components/ActivityIndicator';
import { Button } from '~/ui/components/Button';
import { DIALOG_ANIMATION_DISAPPEAR_MS, DialogContent } from '~/ui/components/Dialog/DialogContent';
import { DialogContentContainer } from '~/ui/components/Dialog/DialogContentContainer';
import { DialogFooter } from '~/ui/components/Dialog/DialogFooter';
import { DialogRoot } from '~/ui/components/Dialog/DialogRoot';
import { DialogTitle } from '~/ui/components/Dialog/DialogTitle';
import { Form } from '~/ui/components/form/Form';
import { FormError } from '~/ui/components/form/FormError';
import { FormGroup } from '~/ui/components/form/FormGroup';
import { FormStates } from '~/ui/components/form/FormStates';
import { Input } from '~/ui/components/form/Input';
import { CALLOUT } from '~/ui/components/text';

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => Promise<BackgroundJobReceiptDataFragment | undefined>;
  onBackgroundJobReceiptPollError: (error: ApolloError) => { errorIndicatesSuccess: boolean };
  onDeletionFinished: () => void;

  title: string;
  preMessageElement?: ReactElement;
  message: ReactNode;

  // when not supplied, a single step dialog will be used.
  // otherwise a multi-step dialog is used and the first step
  // requires the user to type a string verbatim to continue
  // to the second step
  confirmationConfiguration?: {
    requiredConfirmedValue: string;
    confirmationPromptMessage: ReactNode;
  };
};

export function BackgroundDeletionDialog({
  open,
  onClose,
  onConfirm,
  onBackgroundJobReceiptPollError,
  onDeletionFinished,
  title,
  preMessageElement,
  message,
  confirmationConfiguration,
}: Props) {
  const [formStatus, setFormStatus] = useState(FormStates.IDLE);
  const [canRetry, setCanRetry] = useState(true);
  const [error, setError] = useState<string | undefined>();
  const [typedSlugValue, setTypedSlugValue] = useState('');
  const [deletionConfirmed, setDeletionConfirmed] = useState(false);
  const [fetchBackgroundJobReceipt] = useGetBackgroundJobReceiptByIdLazyQuery();

  const isFormNotIdle = formStatus !== FormStates.IDLE;

  function resetState() {
    setError(undefined);
    setFormStatus(FormStates.IDLE);
    setDeletionConfirmed(false);
    setTypedSlugValue('');
  }

  function onOpenChange() {
    // don't allow closing the dialog while submitting
    if (isFormNotIdle) {
      return;
    }
    onClose();

    setTimeout(() => {
      resetState();
    }, DIALOG_ANIMATION_DISAPPEAR_MS * 2);
  }

  async function fetchBackgroundJobReceiptAsync(
    receiptId: string
  ): Promise<[BackgroundJobReceiptDataFragment | undefined, ApolloError | undefined]> {
    const { data, error } = await fetchBackgroundJobReceipt({
      variables: { id: receiptId },
      fetchPolicy: 'network-only',
    });

    return [data?.backgroundJobReceipt.byId, error];
  }

  async function onSubmitAsync() {
    setError(undefined);
    setFormStatus(FormStates.LOADING);

    try {
      const backgroundJobReceipt = await onConfirm();
      if (!backgroundJobReceipt) {
        throw new Error('Failed to start deletion.');
      }

      let secondsPassed = 0;
      const intervalHandle = setInterval(async function pollForDeletionFinishedAsync() {
        function failBackgroundDeletion({
          message,
          canRetryInThisDialog,
        }: {
          message: string;
          canRetryInThisDialog: boolean;
        }) {
          clearInterval(intervalHandle);
          setFormStatus(FormStates.IDLE);
          setCanRetry(canRetryInThisDialog);
          setError(message);
        }

        const [receipt, error] = await fetchBackgroundJobReceiptAsync(backgroundJobReceipt.id);
        if (!receipt) {
          if (error instanceof ApolloError) {
            const { errorIndicatesSuccess } = onBackgroundJobReceiptPollError(error);
            if (errorIndicatesSuccess) {
              clearInterval(intervalHandle);
              setFormStatus(FormStates.SUCCESS);
              onDeletionFinished();
              return;
            }
          }
          return failBackgroundDeletion({
            message: `Deletion failed. Please try again.`,
            canRetryInThisDialog: true,
          });
        }

        // job failed and will not retry
        if (receipt.state === BackgroundJobState.Failure && !receipt.willRetry) {
          console.error(receipt.errorMessage);
          return failBackgroundDeletion({
            message: `${receipt?.errorMessage ? receipt.errorMessage : 'Deletion failed.'}`,
            canRetryInThisDialog: true,
          });
        }

        // all else fails, stop polling after 90 seconds. This should only happen if there's an
        // issue with receipts not setting `willRetry` to false when they fail within a reasonable
        // amount of time.
        if (secondsPassed > 90) {
          console.error(receipt.errorMessage);
          return failBackgroundDeletion({
            message: `Deletion is taking longer than expected. It may still succeed in a few minutes. Wait an hour to see if it is deleted and then try again.`,
            canRetryInThisDialog: false,
          });
        }

        if (receipt.state === BackgroundJobState.Success) {
          clearInterval(intervalHandle);
          setFormStatus(FormStates.SUCCESS);
          onDeletionFinished();
        }

        secondsPassed++;
      }, 1000);
    } catch (error) {
      if (error instanceof ApolloError) {
        const graphqlError = error.graphQLErrors?.length
          ? error.graphQLErrors[0].message
          : error.message;
        setError(graphqlError);
      } else {
        setError((error as Error).message);
      }
      setFormStatus(FormStates.IDLE);
    }
  }

  const mainMessageElement = (
    <div className="flex flex-col gap-1.5">
      {preMessageElement}
      <CALLOUT theme="secondary">{message}</CALLOUT>
      <CALLOUT theme="secondary">This action cannot be undone.</CALLOUT>
    </div>
  );

  const processingElement = (
    <div className="flex flex-col items-center justify-center gap-2 py-4">
      <ActivityIndicator size="lg" className="stroke-icon-default" />
      <CALLOUT theme="secondary" className="text-center">
        Deletion processing...
      </CALLOUT>
    </div>
  );

  if (!confirmationConfiguration) {
    return (
      <DialogRoot open={open} onOpenChange={onOpenChange}>
        <DialogContent className="rounded-lg">
          <Form
            onSubmit={async () => {
              await onSubmitAsync();
            }}
            disabled={isFormNotIdle || !canRetry}
            variant="flat">
            <DialogTitle title={title} icon={<Trash03Icon />} />
            <DialogContentContainer>
              {!isFormNotIdle && mainMessageElement}
              {isFormNotIdle && processingElement}
              <FormError error={error} />
            </DialogContentContainer>
            <DialogFooter className="flex flex-1 items-center justify-end gap-4 px-6">
              <div className="flex items-center gap-2">
                <Button
                  theme="quaternary"
                  disabled={isFormNotIdle || !canRetry}
                  onClick={onOpenChange}>
                  Cancel
                </Button>
                <Button
                  testID="delete-submit-button"
                  disabled={isFormNotIdle || !canRetry}
                  theme="secondary-destructive"
                  type="submit">
                  Delete
                </Button>
              </div>
            </DialogFooter>
          </Form>
        </DialogContent>
      </DialogRoot>
    );
  }

  return (
    <DialogRoot open={open} onOpenChange={onOpenChange}>
      <DialogContent className="rounded-lg">
        <Form
          onSubmit={async () => {
            if (deletionConfirmed) {
              await onSubmitAsync();
            } else {
              setDeletionConfirmed(true);
            }
          }}
          disabled={isFormNotIdle || !canRetry}
          variant="flat">
          <DialogTitle title={title} icon={<Trash03Icon />} />
          <DialogContentContainer>
            {!isFormNotIdle && !deletionConfirmed && (
              <FormGroup
                htmlFor="slugConfirm"
                description={confirmationConfiguration.confirmationPromptMessage}>
                <Input
                  id="slugConfirm"
                  onChange={(event) => setTypedSlugValue(event.target.value)}
                  placeholder={confirmationConfiguration.requiredConfirmedValue}
                  autoComplete="off"
                />
              </FormGroup>
            )}
            {!isFormNotIdle && deletionConfirmed && mainMessageElement}
            {isFormNotIdle && processingElement}
            <FormError error={error} />
          </DialogContentContainer>
          <DialogFooter className="flex flex-1 items-center justify-end gap-4 px-6">
            <div className="flex items-center gap-2">
              <Button
                theme="quaternary"
                disabled={isFormNotIdle || !canRetry}
                onClick={onOpenChange}>
                Cancel
              </Button>
              <Button
                testID="confirm-deletion-button"
                disabled={
                  isFormNotIdle ||
                  typedSlugValue !== confirmationConfiguration.requiredConfirmedValue ||
                  !canRetry
                }
                rightSlot={!deletionConfirmed ? <ChevronRightIcon /> : undefined}
                theme={deletionConfirmed ? 'secondary-destructive' : 'secondary'}
                type="submit">
                {deletionConfirmed ? 'Delete' : 'Confirm'}
              </Button>
            </div>
          </DialogFooter>
        </Form>
      </DialogContent>
    </DialogRoot>
  );
}
