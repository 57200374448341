import { useRouter } from 'next/compat/router';

import { getParamString } from '~/common/getParamString';
import { useScheduleAppDeletionMutation } from '~/graphql/mutations/ScheduleAppDeletionMutation.mutation.generated';
import { AppDataFragment } from '~/graphql/types.generated';
import { BackgroundDeletionDialog } from '~/ui/components/BackgroundDeletionDialog';
import { DEMI } from '~/ui/components/text';

type Props = {
  open: boolean;
  onClose: () => void;
  app: AppDataFragment;
};

export function ProjectDeleteDialog({ open, onClose, app }: Props) {
  const router = useRouter();
  const account = getParamString(router?.query?.account);
  const [scheduleAppDeletionAsync] = useScheduleAppDeletionMutation();

  return (
    <BackgroundDeletionDialog
      open={open}
      onClose={onClose}
      onConfirm={async () => {
        const deleteAppResult = await scheduleAppDeletionAsync({
          variables: { appId: app.id },
        });
        return deleteAppResult.data?.app?.scheduleAppDeletion;
      }}
      onBackgroundJobReceiptPollError={() => ({ errorIndicatesSuccess: false })}
      onDeletionFinished={() => {
        // hard redirect and reload since we can no longer use refetchQueries
        // due to the async nature of project deletion. Also client.refetchQueries
        // doesn't take variables for some reason.
        window.location.href = account ? `/accounts/${account}` : '/';
      }}
      title="Delete project"
      message={
        <>
          Are you sure that you want to delete the <DEMI>{app.fullName}</DEMI> project?
        </>
      }
      confirmationConfiguration={{
        requiredConfirmedValue: app.slug,
        confirmationPromptMessage: (
          <>
            Type <DEMI>"{app.slug}"</DEMI> in the field below to confirm project deletion.
          </>
        ),
      }}
    />
  );
}
